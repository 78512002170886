import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Courier & Warehouse Integration",
  "author": "tzookb",
  "type": "post",
  "date": "2019-05-06T18:17:01.000Z",
  "url": "/2019/05/courier-warehouse-integration/",
  "categories": ["My Projects"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`The Product`}</h3>
    <p><strong parentName="p">{`Tech Stack:`}</strong>{` PHP, Zend Framework, MySql, Guzzle, composer, PHPunit We needed to connect our own system and orders panel to the warehouse and to the shipping courier. The flow of the service: `}</p>
    <ul>
      <li parentName="ul">{`Got a new order on the app`}</li>
      <li parentName="ul">{`order gets verified`}</li>
      <li parentName="ul">{`Warehouse gets notification for the new order and preps the order box`}</li>
      <li parentName="ul">{`The courier gets notified and when it should pickup the package and to where`}</li>
      <li parentName="ul">{`As package is delivered we get a notification from the courier and update our system Learn more on the `}</li>
    </ul>
    <a rel="noreferrer noopener" aria-label="company (opens in a new tab)" href="https://www.golantelecom.co.il" target="_blank">company</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      